<template>
  <v-row>
    <v-col cols="12">
      <v-form ref="form">
        <base-card>
          <v-card-title>Registrar Emisor FE</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  ref="Compania"
                  v-model="dataEmisor.Compania"
                  :items="detailClients"
                  item-text="NombreCompania"
                  item-value="IdCompania"
                  label="Compañia"
                  prepend-inner-icon="mdi-sitemap"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  filter
                  search-input
                  required
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field
                            class="search-field"
                            v-model="searchClients"
                            placeholder="Buscar"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                          ></v-text-field>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='12' sm='4'>
                <v-text-field
                  ref='Nit'
                  v-model='dataEmisor.Nit'
                  :error-messages='errorMessages'
                  clearable
                  type="number"
                  label='Nit'
                  :rules="nitFormRules"
                  prepend-inner-icon='mdi-card'
                  required
                />
              </v-col>

              <v-col cols='12' sm='4'>
                <v-select
                  ref='TipoIdentificacion'
                  v-model='dataEmisor.TipoIdentificacion'
                  :error-messages='errorMessages'
                  :items='listTypeDocument'
                  :rules='formRules'
                  item-text='textTypeDocument'
                  item-value='valueTypeDocument'
                  label='Tipo Identificación'
                  prepend-inner-icon='mdi-account-network'
                  required
                />
              </v-col>

              <v-col cols='12' sm='4'>
                <v-select
                  ref='TipoPersona'
                  v-model='dataEmisor.TipoPersona'
                  :error-messages='errorMessages'
                  :items='listTypePerson'
                  :rules='formRules'
                  item-text='textTypePerson'
                  item-value='valueTypePerson'
                  label='Tipo Persona'
                  prepend-inner-icon='mdi-file-chart'
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='12' sm='6'>
                <v-text-field
                  ref='NombreComercial'
                  v-model='dataEmisor.NombreComercial'
                  :error-messages='errorMessages'
                  :rules='formRules'
                  clearable
                  label='Nombre Comercial'
                  prepend-inner-icon='mdi-account-tie'
                  required
                />
              </v-col>
              <v-col cols='12' sm='6'>
                <v-text-field
                  ref='RazonSocial'
                  v-model='dataEmisor.RazonSocial'
                  v-validate-role='permisosRoles'
                  :error-messages='errorMessages'
                  :rules='formRules'
                  clearable
                  label='Razon Social'
                  prepend-inner-icon='mdi-account-tie'
                  required
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols='12' sm='6'>
                <v-select
                  ref='Tributo'
                  v-model='dataEmisor.Tributo'
                  :error-messages='errorMessages'
                  :items='listTributo'
                  :rules='formRules'
                  item-text='textTributo'
                  item-value='valueTributo'
                  label='Tributo'
                  prepend-inner-icon='mdi-file-chart'
                  required
                />
              </v-col>
              <v-col cols='12' sm='6'>
                <v-select
                  ref='Obligaciones'
                  v-model='dataEmisor.Obligaciones'
                  :error-messages='errorMessages'
                  :items='listObligaciones'
                  :rules='formRules'
                  item-text='textObligaciones'
                  item-value='valueObligaciones'
                  label='Obligaciones'
                  prepend-inner-icon='mdi-file-chart'
                  required
                  multiple
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='12' sm='4'>
                <v-text-field
                  ref='NombreContacto'
                  v-model='dataEmisor.NombreContacto'
                  :error-messages='errorMessages'
                  :rules='formRules'
                  clearable
                  label='Nombre Contacto'
                  prepend-inner-icon='mdi-account-tie'
                  required
                />
              </v-col>
              <v-col cols='12' sm='4'>
                <v-text-field
                  ref='EmailContacto'
                  v-model='dataEmisor.EmailContacto'
                  v-validate-role='permisosRoles'
                  :error-messages='errorMessages'
                  type="email"
                  :rules="[v => /.+@.+\..+/.test(v) || 'Correo no válido']"
                  clearable
                  label='Email Contacto'
                  prepend-inner-icon='mdi-email'
                  required
                />
              </v-col>
              <v-col cols='12' sm='4'>
                <v-text-field
                  ref='TelefonoContacto'
                  v-model='dataEmisor.TelefonoContacto'
                  v-validate-role='permisosRoles'
                  :error-messages='errorMessages'
                  :rules='formRules'
                  clearable
                  type="number"
                  label='Teléfono Contacto'
                  prepend-inner-icon='mdi-phone'
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  ref="Departamento"
                  v-model="dataEmisor.Departamento"
                  :items="detailDepartaments"
                  item-text="nombre"
                  item-value="idProvincia"
                  label="Departamento"
                  prepend-inner-icon="mdi-map"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  filter
                  search-input
                  required
                  @change="getCitys"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field
                            class="search-field"
                            v-model="searchDepatartaments"
                            placeholder="Buscar"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                          ></v-text-field>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  ref="Ciudad"
                  v-model="dataEmisor.Ciudad"
                  :items="detailCitys"
                  item-text="nombre"
                  item-value="idPoblacion"
                  label="Ciudad"
                  prepend-inner-icon="mdi-city"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  filter
                  search-input
                  required
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-text-field
                            class="search-field"
                            v-model="searchCitys"
                            placeholder="Buscar"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                          ></v-text-field>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>

              <v-col cols='12' sm='4'>
                <v-text-field
                  ref='Direccion'
                  v-model='dataEmisor.Direccion'
                  v-validate-role='permisosRoles'
                  :error-messages='errorMessages'
                  :rules='formRules'
                  clearable
                  label='Direccion'
                  prepend-inner-icon='mdi-home'
                  required
                />
              </v-col>
              <v-col cols="12" sm="9">
                  <v-alert v-show="successAlert" :type="alertType" text>
                    {{ msgAlert }}
                  </v-alert>
              </v-col>
                <v-col cols="12" sm="3">
                <v-btn
                  class="ma-2"
                  style="
                    background-color: rgb(204 33 40);
                    color: white !important;
                  "
                  @click="validateEmisor"
                >
                  Registrar
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="GRAY"
                  @click="limpiarFormulario"
                >
                  Limpiar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </base-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>

import {
  listTypeDocument,
  listTributo,
  listObligaciones, listTypePerson,
} from '@/data/listConfigEmisor'

export default {
  name: "RegistrarEmisor",
  data() {
    return {
      permisosRoles: ["administrador"],
      dataEmisor: {},
      Nit: '',
      RazonSocial: '',
      NombreComercial: '',
      Direccion: '',
      NombreContacto: '',
      EmailContacto: '',
      TelefonoContacto: '',
      listTypeDocument: listTypeDocument,
      listTributo: listTributo,
      listTypePerson: listTypePerson,
      listObligaciones: listObligaciones,
      errorMessages: "",
      searchClients: '',
      searchDepatartaments: '',
      searchCitys: '',
      formHasErrors: null,
      successAlert: false,
      alertType: "success",
      msgAlert: "",
      selectedText: '', // Almacena el text correspondiente al value seleccionado
      formRules: [(v) => !!v || "Este campo es requerido"],
      nitFormRules: [
        (v) => !!v || "Este campo es requerido y SIN dígito de verificación",
        (v) => v > 0 || "Este campo no acepta números negativos",
      ],
    };
  },
  computed: {
    form() {
      return {
        Compania: this.dataEmisor.Compania,
        Nit: this.dataEmisor.Nit,
        TipoIdentificacion: this.dataEmisor.TipoIdentificacion,
        TipoPersona: this.dataEmisor.TipoPersona,
        NombreComercial: this.dataEmisor.NombreComercial,
        RazonSocial: this.dataEmisor.RazonSocial,
        NombreContacto: this.dataEmisor.NombreContacto,
        EmailContacto: this.dataEmisor.EmailContacto,
        TelfonoContacto: this.dataEmisor.TelfonoContacto,
        Tributo: this.dataEmisor.Tributo,
        Obligaciones: this.dataEmisor.Obligaciones,
        Departamento: this.dataEmisor.Departamento,
        Ciudad: this.dataEmisor.Ciudad,
      };
    },
    statusResponseEmisor() {
      return this.$store.state.emisor.statusResponse;
    },
    statusResponseEmisorMsg() {
      return this.$store.state.emisor.statusResponseMsg;
    },
    detailClients() {
      return (this.$store.state.emisor.listClients.Mensaje || [])
        .filter((compania) =>
          compania.NombreCompania.toLowerCase().includes(
            this.searchClients.toLowerCase()
          )
        )
        .map((compania) => {
          return {
            ...compania,
            NombreCompania: `${compania.NombreCompania.toUpperCase()} - ${compania.IdCompania}`,
          };
        })
        .sort((a, b) => {
          if (a.NombreCompania < b.NombreCompania) return -1;
          if (a.NombreCompania > b.NombreCompania) return 1;
          return 0;
        });
    },
    detailDepartaments() {
      return (this.$store.state.emisor.listDepartaments.Mensaje || [])
        .filter((departament) =>
          departament.nombre.toLowerCase().includes(
            this.searchDepatartaments.toLowerCase()
          )
        )
        .map((departament) => {
          return {
            ...departament,
            nombre: `${departament.nombre.toUpperCase()} - ${departament.idProvincia}`,
          };
        })
        .sort((a, b) => {
          if (a.nombre < b.nombre) return -1;
          if (a.nombre > b.nombre) return 1;
          return 0;
        });
    },
    detailCitys() {
      return (this.$store.state.emisor.listCitys.Mensaje || [])
        .filter((city) =>
          city.nombre.toLowerCase().includes(
            this.searchCitys.toLowerCase()
          )
        )
        .map((city) => {
          return {
            ...city,
            nombre: `${city.nombre.toUpperCase()} - ${city.idPoblacion}`,
          };
        })
        .sort((a, b) => {
          if (a.nombre < b.nombre) return -1;
          if (a.nombre > b.nombre) return 1;
          return 0;
        });
    },
  },
  created () {
    this.getListProvinces()
    this.getListClients()
  },
  watch: {
    formHasErrors() {
      if (this.formHasErrors === false) {
        //this.sendEmisor();
      }
      this.formHasErrors = null;
    },
    statusResponseEmisor() {
      if (this.statusResponseEmisor === false) {
        this.generateAlert('success', this.statusResponseEmisorMsg);
        this.formHasErrors = true;
        this.$store.dispatch("emisor/setResponseEmisor", -1);
      } else if (this.statusResponseEmisor === true) {
        this.generateAlert('error', this.statusResponseEmisorMsg);
        this.$store.dispatch("emisor/setResponseEmisor", -1);
      }
    }
  },
  methods: {
    async getListProvinces() {
      await this.$store.dispatch('emisor/getListProvinces')
    },
    async getListClients() {
      await this.$store.dispatch('emisor/getListClients')
    },
    async getCitys(selectedDepartament) {
      await this.$store.dispatch('emisor/getListCitys', {departamento: selectedDepartament});
    },
    generateAlert(typeAlert, msj) {
      this.successAlert = true;
      this.msgAlert = msj;
      this.alertType = typeAlert;
      if (typeAlert == 'success') {
        this.$store.dispatch("emisor/getListEmisor");
        this.$store.dispatch("emisor/getListClients");
        this.clearForm();
      }

      setTimeout(() => {
        this.successAlert = false;
      }, 3000);
    },
    clearForm() {
      this.$refs.form.reset();
    },
    validateEmisor() {

      this.formHasErrors = !this.$refs.form.validate();

      if (this.formHasErrors === false) this.sendEmisor();

    }, limpiarFormulario() {
      this.clearForm();
    },

    sendEmisor() {

      this.selectedValueTributo = this.dataEmisor.Tributo;
      const selectedTributo = this.listTributo.find(
        (item) => item.valueTributo === this.selectedValueTributo
      );

      if (selectedTributo) {
        // Extraer lo que está después de [XX]
        const match = selectedTributo.textTributo.match(/\[.*?\]\s*(.*)/);
        this.dataEmisor.NombreTributo = match ? match[1] : ''; // Asigna solo el texto después de los corchetes
      } else {
        this.dataEmisor.NombreTributo = '';
      }


      this.selectedValuePersona = this.dataEmisor.TipoPersona;
      const selectedPersona = this.listTypePerson.find(
        (item) => item.valueTypePerson === this.selectedValuePersona
      );
      this.dataEmisor.NombreTipoPersona = (selectedPersona ? selectedPersona.textTypePerson : '');

      this.selectedValueIdentificacion = this.dataEmisor.TipoIdentificacion;
      const selectedIdentificacion = this.listTypeDocument.find(
        (item) => item. valueTypeDocument === this.selectedValueIdentificacion
      );
      this.dataEmisor.NombreTipoIdentificacion = (selectedIdentificacion ? selectedIdentificacion.textTypeDocument : '');
      this.dataEmisor.Pais ="COLOMBIA";
      this.$store.dispatch("emisor/insertEmisor", {
        ...this.dataEmisor,
      });
    },
  }
};
</script>
